.helper-card {
  border: 10px solid $darkblue;
  border-radius: 20px;
  width: 80%;
  margin: 0 auto;
}

@media (max-width: 797px) {
  .helper-card {
    width: 100%;
  }
  
}