@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");
@import "./colors";

.flex-container {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

html,
body {
  font-family: "Lato", sans-serif;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

// headers

h1 {
  font-size: 150%;
  font-weight: bolder;
}

h2 {
  font-size: 150%;
  font-weight: normal;
}

.clickable {
  cursor: pointer;
}

.title {
  display: inline-block;
  padding-left: 2%;
  width: max-content;
}

a {
  text-decoration: none;
  color: $darkblue;

  &.no-link {
    color: $white;
  }
}

.empty-text {
  font-size: 110%;
  color: $darkblue;
  margin-top: 2%;
}

.no-button {
  border: none;
  outline: none;
  background-color: unset;
  display: inline-block;
  text-decoration-line: underline;
  font-size: 110%;
}

.blue-button {
  margin: auto;
  display: flex;
  background: $darkblue;
  border: 4px solid $darkblue;
  width: 80%;
  padding: 2%;
  color: $white;
  border-radius: 20px;
  font-size: 150%;
  font-weight: bold;
  justify-content: center;
}

.blue-button:hover {
  background-color: $white;
  color: $darkgray;
}

.save-button {
  border: none;
  background: $darkblue;
  border: 4px solid $darkblue;
  padding: 2%;
  color: $white;
  border-radius: 10px;
  font-size: 110%;
  font-weight: bold;
  min-width: 80px;
  margin-bottom: 10px;
}

.save-button:hover {
  background-color: $white;
  color: $darkgray;
  border: 4px solid $darkblue;
}

.h2-title {
  font-weight: bold;
  font-size: 150%;
  color: $darkgray;
}

@media (max-width: 1065px) {
  .show-on-mobile {
    display: block;
  }

  .show-on-desktop {
    display: none;
  }
}

@media (min-width: 1065px) {
  .show-on-mobile {
    display: none;
  }

  .show-on-desktop {
    display: block;
  }
}

.inactive-label {
  width: 170px;

  input {
    margin: 10px;
  }
}

.active-label {
  width: 170px;

  input {
    margin: 10px;
  }
}
