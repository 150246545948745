@import "./colors";


.form-control {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  display: inline;

  &[placeholder] {
    font-size: 100%;
  }

  &.no-border-bottom {
    border-bottom: none;
  }
}

.input-wrapper {
  //display: flex;
  white-space: nowrap;
  width: 90%;
  position: relative;
  //flex-wrap: wrap;

  &.disabled {
    pointer-events: none;
    select {
      background: $gray;
      opacity: 0.5;
    }
  }

  label {
    line-height: 37px;
    padding-right: 20px;
    width: 30%;
    white-space: nowrap;

    & + .form-control,
    & + .react-datepicker-wrapper {
      width: 65%;
      display: block;
    }
  }
  .input-offset {
    margin-left: 0%;
    width: 80%;
  }

  .eye-icon {
    position: absolute;
    right: -26px;
    top: -4px;
  }
}

.form-group > .text-danger {
  float: right;
}

.eye-icon {
  margin-top: 10px;
  margin-left: 10px;
}

.icon-mail {
  width: 30px;
  height: auto;
  top: 31px;
  position: relative;
}


.login-form-component {

  .input-wrapper {
  
    label {
      & + .form-control,
      & + .react-datepicker-wrapper {
        width: 80%;
        display: inline;
        background: $white;
      }
    }
  }
}

.btn-primary {
  background-color: $darkblue;
}

.person-advanced-form {

  .form-mb-5 {
    margin-top: 1%;
  }

  .card-person-advanced-data {
    margin: 0 0;
  }


  .mb-4 {
    margin: 0 0;
    padding: 0%;
  }

  .d-grid-log {
    height: 100px;
    width: 190px;
    position: relative;
    float: right;
    margin-top: 30px;

    .no-button {
      width: 135px;
      left: 10px;
      position: relative;
      padding: 10px;
      height: 60px;
    }
  }
}

@media (max-width: 797px) {
  .input-wrapper label + .form-control {
    width: 100%;
  }

  .form-control { 
    border: 4px solid $whiteblue;
  }

  .visit-search .input-wrapper {
    border: 4px solid $whiteblue;
  }   
 
}
