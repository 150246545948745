@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");
@import "../../../styles/colors";

.header {
  padding-bottom: 20px;
  padding-top: 20px;

  a {
    display: inline-flex;
  }

  .logo-wrapper {
    width: 50%;
  }

  .name-wrapper {
    width: 50%;
    text-align: right;
    margin-top: 70px;
    padding-right: 0;

    span {
      background-color: $activ;
      border-radius: 5px;
      color: $white;
      padding: 5px;
      font-weight: 500;

      svg {
        margin-right: 5px;
      }
    }
  }

  @media screen and (max-width: 460px) {
    .name-wrapper {
        width: 100%;
        margin-top: 10px;
    }
    
  }
}

.header-button {
  padding-top: 30px;
  border: none;
  outline: none;
  box-shadow: none;
  background: none;
  color: $header-text;
  font-family: "Lato", sans-serif;
  font-size: 150%;
  font-weight: 900;
  text-align: left;
  line-height: 1.4;
  text-decoration-line: none;
  max-width: 90%;
  width: 200px;
}

.logo-header {
  width: 28%;
  max-height: 125px;
  max-width: 125px;
  min-width: 60px;
}

@media (min-width: 1065px) {
  .logo-header-on-homepage {
    display: none;
  }
}

@media (max-width: 1065px) {
  .logo-header-on-homepage {
    display: inline-block;
  }
}

@media (max-width: 460px) {
  .logo-header-on-homepage {
    top: -18px;
  }

  .header-button {
    font-size: 80%;
  }
}
