@import '../../../styles/colors';

.row-footer {
  width: 90%;
}

.col-footer {
  width: 70px;
}

.footer {
  position: absolute;
  margin: 0 auto;
  right: 0;
  bottom: 0;
  left: 0;
  height: 160px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 30px 0;
  background-color: $darkgray;
  span {
    color: $white;
  }
}
