@import "./styles/colors";


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.global-loader-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: lightgray;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  z-index: 1056;
  height: 500%;

  .global-loader {
    position: relative;
    top: 313px;
    text-align: center;

    .logo {
      width: 15vw;
      position: relative;
      margin-bottom: 50px;
    }
  }
}
