$blue: #8ED8F8;
$darkblue: #105680;
$darkgray: #393939;
$lightgray: #C4C4C4;
$gray: #888888;
$white: #ffffff;
$header-text: #105680;
$whiteblue: #E2F6FF;
$activ: #55c255;
$no-activ: #f0698a;
